// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCIP5HdPl9LIKS1R9LMGwHvcqlJDn_u5ks",
  authDomain: "chat-4c5d3.firebaseapp.com",
  projectId: "chat-4c5d3",
  storageBucket: "chat-4c5d3.appspot.com",
  messagingSenderId: "1077626357141",
  appId: "1:1077626357141:web:926b31009af90edc8de29c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
