import React, { useState } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const Message = ({ message }) => {
  const [decoding, setDecoding] = useState(false)
  const [decoded, setDecoded] = useState("")

  const decodeToggle = () => {
    setDecoding(!decoding)
    if(decoding){
      console.log("atob(message.text): ", atob(message.text))
      setDecoded(atob(message.text))
    }else{
      setDecoded("")
    }
  }
  
  const [user] = useAuthState(auth);
  return (
    <div className={`chat-bubble ${message.uid === user.uid ? "right" : ""}`}>
      <img
        className="chat-bubble__left"
        src={message.avatar}
        alt="user avatar"
      />
      <div className="chat-bubble__right">
        <p className="user-name">{message.name}</p>
        <p className="user-message">{message.text}</p>
        <button onClick={(e) => decodeToggle()}>Toggle</button>
        {
          decoded && decoded !=="" ? (<p>{decoded}</p>) : (<></>)
        }
      </div>
    </div>
  );
};

export default Message;
